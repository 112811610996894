import React from 'react';
import serialize from 'serialize-javascript';

import Head from 'nordic/head';
import MeliGA from 'nordic/analytics/meli-ga';
import Style from 'nordic/style';
import injectI18n from 'nordic/i18n/injectI18n';
import Script from 'nordic/script';
import Hotjar from 'nordic/hotjar';

import Body from './components/body';

const getFontUrl = (type) =>
  `https://http2.mlstatic.com/ui/webfonts/v3.0.0/proxima-nova/proximanova-${type}.woff2`;

const Fonts = () => (
  <Head>
    <link
      rel="preload"
      href={getFontUrl('light')}
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
    <link
      rel="preload"
      href={getFontUrl('regular')}
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
    <link
      rel="preload"
      href={getFontUrl('semibold')}
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
    <style>
      {
        '@font-face{font-family:\'Proxima Nova\';font-weight:300;font-style:normal;src:url(https://http2.mlstatic.com/ui/webfonts/v3.0.0/proxima-nova/proximanova-light.woff2) format("woff2"),url(https://http2.mlstatic.com/ui/webfonts/v3.0.0/proxima-nova/proximanova-light.woff) format("woff"),url(https://http2.mlstatic.com/ui/webfonts/v3.0.0/proxima-nova/proximanova-light.ttf) format("truetype")}@font-face{font-family:\'Proxima Nova\';font-weight:400;font-style:normal;src:url(https://http2.mlstatic.com/ui/webfonts/v3.0.0/proxima-nova/proximanova-regular.woff2) format("woff2"),url(https://http2.mlstatic.com/ui/webfonts/v3.0.0/proxima-nova/proximanova-regular.woff) format("woff"),url(https://http2.mlstatic.com/ui/webfonts/v3.0.0/proxima-nova/proximanova-regular.ttf) format("truetype")}@font-face{font-family:\'Proxima Nova\';font-weight:600;font-style:normal;src:url(https://http2.mlstatic.com/ui/webfonts/v3.0.0/proxima-nova/proximanova-semibold.woff2) format("woff2"),url(https://http2.mlstatic.com/ui/webfonts/v3.0.0/proxima-nova/proximanova-semibold.woff) format("woff"),url(https://http2.mlstatic.com/ui/webfonts/v3.0.0/proxima-nova/proximanova-semibold.ttf) format("truetype")}'
      }
    </style>
  </Head>
);

/**
 * View Component
 */
const View = (props) => {
  const { siteId, lowEnd, deviceType, company, translations } = props;
  const base =
    'https://http2.mlstatic.com/frontend-assets/ml-web-navigation/ui-navigation/5.21.22/mercadolibre/';

  return (
    <div>
      <MeliGA section="/SHIPPING" page="/LANDING-ENVIOS/" />
      <Head>
        <title>Envíos de Mercado Libre</title>
        <link rel="shortcut icon" href={`${base}favicon.ico`} />
        <link rel="apple-touch-icon" sizes="76x76" href={`${base}76px.png`} />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href={`${base}120px.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href={`${base}152px.png`}
        />
      </Head>
      <Fonts />
      <Style href="https://http2.mlstatic.com/static/org-img/ui/font/lato/v11/100-300-400-700.min.css" />
      <Style
        href="https://http2.mlstatic.com/ui/navigation/4.1.3/mercadolibre/navigation.css"
        preload
      />
      <Style href="enviosMLM.css" />
      <Script>
        {`
        window.__PRELOADED_STATE__ = ${serialize(
          { siteId, lowEnd, deviceType, company, translations },
          { isJSON: true },
        )};
      `}
      </Script>
      <Script src="vendor.js" />
      <Script src="enviosMLM.js" />
      <Body />
      <Hotjar id={641080} traffic={{ from: 0, to: 99 }} />
    </div>
  );
};

export default injectI18n(View);
