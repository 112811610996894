import React from 'react';
import PropTypes from 'prop-types';

import Integrate from '@components/envios/integrate';
import Banner from '@components/envios/banner';
import Questions from '@components/envios/questions';

import Benefits from '../benefits';

const faqs = [
  {
    question: '¿En qué productos ofreceré Envíos de Mercado Libre?',
    answer: (
      <>
        En todos tus productos que puedan enviarse por correo. Si tienes dudas
        sobre algún producto, puedes revisar{' '}
        <a
          href="https://www.mercadolibre.com.mx/ayuda/Dimensiones-maximas-perm-tidas_3163"
          target="_blank"
          rel="noopener noreferrer"
        >
          cuáles son las dimensiones máximas permitidas para enviar paquetes
        </a>
        .
      </>
    ),
  },
  {
    question: '¿En cuáles ofreceré envío gratis?',
    answer: (
      <>
        Ofrecerás envíos gratis si tu producto es nuevo y el precio de la venta
        es $ 299 o más; o si te compran varios productos y el total de la venta
        supera este monto.{' '}
        <a
          href="https://www.mercadolibre.com.mx/ayuda/costos-envios-gratis_3287"
          target="_blank"
          rel="noopener noreferrer"
        >
          Revisar costos y descuentos
        </a>
      </>
    ),
  },
  {
    question: '¿Cuánto pagaré por los envíos?',
    answer: (
      <>
        El costo del envío dependerá del peso y del tamaño del paquete. Tendrás
        descuentos según tu reputación.{' '}
        <a
          href="https://www.mercadolibre.com.mx/ayuda/costos-envios-gratis_3287"
          target="_blank"
          rel="noopener noreferrer"
        >
          Revisar costos y descuentos
        </a>
      </>
    ),
  },
  {
    question: '¿Cuándo tendré disponible el dinero de mi venta?',
    answer: (
      <>
        Si tienes reputación por vender en Mercado Libre y el producto es nuevo,
        tendrás tu dinero disponible 2 días después de la entrega del producto.
        <br />
        <br />
        En cambio, si no tienes reputación por vender en Mercado Libre o el
        producto es usado, tendrás tu dinero disponible 6 días después de la
        entrega del producto.
        <br />
        <br />
        <a
          href="https://reputacion.mercadolibre.com.mx/myReputation"
          target="_blank"
          rel="noopener noreferrer"
        >
          Consultar mi reputación
        </a>
      </>
    ),
  },
  {
    question: '¿Dónde se envían los productos?',
    answer: (
      <>
        Puedes llevar tus productos a cualquiera de nuestras{' '}
        <a
          href="https://envios.mercadolibre.com.mx/agenciesList/showAgenciesMap"
          target="_blank"
          rel="noopener noreferrer"
        >
          agencias de Mercado Libre
        </a>
        .
      </>
    ),
  },
  {
    question: '¿Cuándo tengo que enviar los productos?',
    answer: (
      <>
        Una vez que vendas, prepara el producto y envíalo a tiempo para brindar
        la mejor experiencia a las personas que te compran y no afectar{' '}
        <a
          href="https://www.mercadolibre.com.mx/ayuda/Como-funciona-la-reputacion-vendedor_866"
          target="_blank"
          rel="noopener noreferrer"
        >
          tu reputación por vender en Mercado Libre
        </a>
        . En cada venta te ayudaremos con indicaciones para que envíes a tiempo.
      </>
    ),
  },
];

const Body = (props) => {
  const { className } = props;

  return (
    <div className={className}>
      <Banner
        title="Envía tus ventas con Mercado Libre"
        text=" Ofrecerás envíos gratis con hasta 50% de descuento y tus productos estarán destacados en todas las búsquedas."
      />
      <Benefits />
      <Questions title="Conoce los detalles" faqs={faqs} />
      <Integrate
        title={
          <>
            Seamos un equipo <br /> Envía tus ventas con Mercado Libre
          </>
        }
        link="https://www.mercadolibre.com.mx/publicaciones/"
        buttonText="Ir a Publicaciones"
      />
    </div>
  );
};

Body.propTypes = {
  className: PropTypes.string,
};

Body.defaultProps = {
  className: 'body',
};

export default Body;
